// email-actions.controller.js extends this controller's parent controller and contains many functions used for managing messages (move, delete, block, mark, print, etc.)

(function () {
    'use strict';
    angular
        .module('smartermail')
        .controller('messageController', messageController);

    function messageController($rootScope, $scope, $http, $timeout, $q, $filter, $translate, $window, preferencesStorage,
        userDataService, coreDataSettings, coreDataContacts, coreDataCategories, toaster, apiCategories, messageData,
        spinnerFactory, errorHandling, successHandling, errorMessageService, popupService, restrictedSenders, userTimeService,
        emailFunctions, emailInviteFunctions, emailTracker, emailNavigation) {

        // Properties ----------------------------
        var vm = this;
        var loadTextTimeout;

        $scope.activeTrackersCount = 0;
        $scope.contacts = [];
        $scope.convertLocalToUserTime = userTimeService.convertLocalToUserTime;
        $scope.hideNoMessageText = true;
        $scope.isPopout = window.location.href.indexOf('#/popout/') != -1;
        $scope.isReadOnly = true; // We set it to true here to default the buttons to hidden by default
        $scope.showTrustInfo = false;
        $scope.spinner = new spinnerFactory();
        messageData.senderIsBlocked = false;
        vm.emailCtrl = undefined;
        vm.iFrameLoaded = false;
        vm.isLoaded = false;

        // Exported Functions --------------------
        $scope.forwardClicked = forwardClicked;
        $scope.isEmailUsers = isEmailUsers;
        $scope.onDownloadRaw = onDownloadRaw;
        $scope.replyClicked = replyClicked;
        vm.allowRemoteForMessage = allowRemoteForMessage;
        vm.categoryColorByName = categoryColorByName;
        vm.closeWindow = closeWindow;
        vm.inviteAccept = inviteAccept;
        vm.inviteTentativeAccept = inviteTentativeAccept;
        vm.inviteCheck = inviteCheck;
        vm.inviteDecline = inviteDecline;
        vm.inviteDelete = inviteDelete;
        vm.inviteDetails = inviteDetails;
        vm.openProposeChangesModal = openProposeChangesModal;
        vm.onCategoryChange = onCategoryChange;
        vm.onClearAllCategories = onClearAllCategories;
        vm.proposalAccept = proposalAccept;
        vm.rejectReadReceipt = rejectReadReceipt;
        vm.resetCategoryForm = resetCategoryForm;
        vm.saveCategoryChanges = saveCategoryChanges;
        vm.setCategoryForm = setCategoryForm;
        //vm.ownerHasCategories = ownerHasCategories;

        activate();

        // ---------------------------------------

        function activate() {
            userTimeService.init();
            init();

            $(document).ready(function () {
                $(document).on("keydown", ListenPrintHotkey);
            });

            $scope.$on("mail.mailListLoaded", onMailListLoaded);
            $scope.$on("mail.mailListUnloaded", onMailListUnloaded);
            $scope.$on("mail.selectionCountChanged", onMailSelectionCountChanged);
            $scope.$on("loadMessage", loadMessage);
            $scope.$on("unloadMessage", unloadMessage);
            $scope.$on("mail.refreshView", onRefreshView);
            //$scope.$on("loadPopout", loadPopout);
            $(window).on("resize.doResize", doResize);
            $scope.$on("resize.doResize", doResize);
            $scope.$on("$destroy", onDestroy);
            window.addEventListener('storage', onStorageChanged);

            if (!$scope.isPopout)
                $timeout(function () { $scope.hideNoMessageText = false; }, 300);
        }

        async function init() {
            try {
                await userDataService.init(true);
                if (!userDataService.user.isSysAdmin && !userDataService.user.isPrimarySysAdmin) {
                    const results = await Promise.all([restrictedSenders.init()]);

                }
            } catch (err) {
                errorHandling.report(err);
            }

            if ($scope.isPopout) {
                loadMessage(null, messageData.navigationPacket);
            }
        }

        function reloadMessage(fullReload) {
            let newNavPacket = emailNavigation.makeEmailPacket(messageData.navigationPacket.owner, messageData.navigationPacket.folder, messageData.navigationPacket.uid, undefined, { softReload: !fullReload });
            loadMessage(undefined, newNavPacket);
        }
        let fetchCancelers = [];
        async function loadMessage(event, params) {
            if (vm.emailCtrl && vm.emailCtrl.selectedCount === 0) {
                vm.messageDoesNotExist = false;
                messageData.unloadMessage();
                return;
            }
            // cancel previous fetches if not complete.
            fetchCancelers.forEach((canceler) => canceler.resolve());
            
            //if (params && !params.softReload) {
            //    messageData.unloadMessage();
            //}
            const spinnerToUse = messageData.messageLoaded && $rootScope.spinner && $rootScope.spinner.isShown() 
                ? $rootScope.spinner 
                : $scope.spinner;
            spinnerToUse.show(messageData.messageLoaded ? 1000 : 250);
            vm.isSoftReload = params && params.softReload;
            if (!vm.isSoftReload && messageData.messageLoaded) {
                messageData.unloadMessage();
            }
            if (params && params.mid && !params.uid) {
                try {
                    // Call API to translate mid to uid
                    let requestParameters = {
                        'OwnerEmailAddress': params.owner,
                        'Folder': params.folder,
                        'Mid': params.id,
                    };

                    const uidLookupResult = await $http.post("~/api/v1/mail/mid-to-uid", requestParameters);
                    params.mid = undefined;
                    params.uid = uidLookupResult.data.uid;
                } catch (err) {
                    // Ignore lookup error here. We'll just show the message not found error below
                }
            }

            if (params)
                messageData.navigationPacket = emailNavigation.makeEmailPacket(params.owner, params.folder, params.uid, undefined);

            $scope.hideNoMessageText = true;

            if (messageData.navigationPacket.uid != undefined)
                messageData.message.uid = messageData.navigationPacket.uid;
            messageData.message.owner = messageData.navigationPacket.owner;

            var parameters = {};
            if (messageData.navigationPacket.uid != undefined) {
                parameters = {
                    'OwnerEmailAddress': messageData.navigationPacket.owner,
                    'Folder': messageData.navigationPacket.folder,
                    'UID': messageData.navigationPacket.uid,
                };
            }

            var url = "~/api/v1/mail/message";
            if (messageData.navigationPacket.reply === 3)
                url += "/forward";
            $scope.isReadOnly = true;
            
            // Create a canceler promise so the interceptor can detect if it has been resolved
            function createCancelerPromise() {
                const defer = $q.defer();
                defer.promise.isResolved = false; // Track the state of the promise

                defer.promise.finally(() => {
                    defer.promise.isResolved = true; // Mark as resolved when it completes
                });
                return defer;
            }
            const messageDataCanceler = createCancelerPromise();
            const messageDataPromise = $http.post(url, parameters, { timeout: messageDataCanceler.promise });
            fetchCancelers.push(messageDataCanceler);
            
            const listFoldersCanceler =  createCancelerPromise();
            const listFoldersPromise = $http.get("~/api/v1/folders/list-email-folders", { timeout: listFoldersCanceler.promise });
            fetchCancelers.push(listFoldersCanceler);
            
            $q
                .all([messageDataPromise, listFoldersPromise])
                .then(function (success) {
                    if (success[0]) processMessage(success[0]);
                    if (success[1]) processFolder(success[1]);
                }, (error) => {
                    if (error && error.status === -1){
                        console.log("loadMessage requests were cancelled by the user");
                        // request was cancelled
                    } else {
                        processMessage(error);
                    }
                })
                .finally(() => {
                    spinnerToUse.hide();
                    fetchCancelers = [];
                })

            function processFolder(response) {
                vm.isInSharedFolder = false;
                for (let i = 0; i < response.data.count; ++i) {
                    const folder = findFolderRecursive(response.data.folderList[i]);
                    if (!folder)
                        continue;
                    vm.isInSharedFolder = folder.access && folder.access != 0;
                    messageData.prop.access = folder.access;
                    $scope.isReadOnly = folder.access === 2;
                    break;
                }

                function findFolderRecursive(folderObj) {
                    if (folderObj.ownerEmailAddress.toLowerCase() === $scope.getActiveFolderOwner().toLowerCase() &&
                        folderObj.path.toLowerCase() === $scope.getActiveFolder().toLowerCase())
                        return folderObj;

                    if (folderObj.subFolders) {
                        for (let i = 0; i < folderObj.subFolders.length; ++i) {
                            const found = findFolderRecursive(folderObj.subFolders[i]);
                            if (found)
                                return found;
                        }
                    }

                    return false;
                }
            }

            function processMessage(response) {
                try {
                    //const spinnerToUse = $rootScope.spinner || $scope.spinner;
                    //spinnerToUse.hide();
                    if ((!response.data.messageData.uid) || (response.data.messageData.uid === undefined)) {
                        messageData.unloadMessage();
                        vm.messageDoesNotExist = true;
                        $rootScope.$broadcast('mail.messageDoesNotExist', vm.messageDoesNotExist);
                        return;
                    }

                    if (messageData.message.mid === response.data.messageData.mid) {
                        messageData.message.uid = response.data.messageData.uid;
                    }

                    if (messageData.message.uid === response.data.messageData.uid) {
                        $scope.hadUnsafeCode = response.data.messageData.hadUnsafeCode;
                        $scope.htmlIsStillUnsafe = response.data.messageData.htmlIsStillUnsafe;
                        $scope.imagesBlocked = response.data.messageData.imagesBlocked;
                        $scope.cleansedFromAddress = response.data.messageData.cleansedFromAddress;
                        messageData.loadMessage(response.data.messageData);

                        $scope.isDeleted = messageData.message.isDeleted === undefined ? false : messageData.message.isDeleted;

                        if (messageData.navigationPacket.mode) {
                            messageData.mode = messageData.navigationPacket.mode;
                        } else {
                            if ((messageData.message.folder || "").toLowerCase() !== "junk e-mail")
                                messageData.mode = 'html';
                            else {
                                if (messageData.message.bypassRemoteContent & vm.isSoftReload)
                                    messageData.mode = 'html';
                                else
                                    messageData.mode = 'text';
                            }
                        }

                        $timeout(function () { $scope.viewAsClicked(messageData.mode); });
                        // This isn't getting hit on switching sections

                        vm.isInJunk = messageData.message.folder && (messageData.message.folder.toLowerCase().indexOf('junk e-mail') !== -1);
                        vm.isInDeleted = messageData.message.folder && (messageData.message.folder.toLowerCase().indexOf('deleted items') !== -1);
                        vm.isSentItems = messageData.message.folder && (messageData.message.folder.toLowerCase().indexOf('sent items') !== -1);
                        vm.isUsersEmail = (messageData.message.fromAddress.email ? messageData.message.fromAddress.email : messageData.message.fromAddress) === userDataService.user.emailAddress;


                        function calcActiveTrackers() {
                            if (coreDataSettings.userSettings.remoteTrackersExceptions && coreDataSettings.userSettings.remoteTrackersExceptions.length > 0
                                && messageData.message.foundTrackers && messageData.message.foundTrackers.length > 0) {
                                vm.activeTrackers = messageData.message.foundTrackers;
                                messageData.message.foundTrackers = vm.activeTrackers.filter((at) => {
                                    var found = false;
                                    if (coreDataSettings.userSettings.remoteTrackersExceptions.includes(at.id))
                                        found = true;
                                    return !found;
                                });
                            }

                            if (messageData.message.foundTrackers && messageData.message.foundTrackers.length > 0) {
                                for (var i = 0; i < messageData.message.foundTrackers.length; i++) {
                                    var id = messageData.message.foundTrackers[i].id;
                                    messageData.message.foundTrackers[i].allowed = false;
                                    messageData.message.foundTrackers[i].revert = function () {
                                        calcActiveTrackers();
                                        var iframe = document.getElementById('messageViewFrame');
                                        if (iframe && iframe.contentWindow && iframe.contentWindow.allowTracker)
                                            iframe.contentWindow.allowTracker(id);
                                    };
                                }
                                $scope.activeTrackersCount = messageData.message.foundTrackers.length;
                            } else {
                                $scope.activeTrackersCount = 0;
                            }
                        }
                        calcActiveTrackers();

                        $scope.convertLocalToUserTime(messageData.message.date)
                            .then(function (success) {
                                if (success.getSeconds() >= 30) {
                                    success.setMinutes(success.getMinutes() + 1);
                                }
                                success.setSeconds(0);
                                messageData.message.userDate = success;
                                messageData.message.userDataMoment = moment(success).calendar();
                            },
                                function (failure) {
                                    errorHandling.report(failure);
                                });

                        if (messageData.message.fromAddress.email) {
                            var fromAddr = coreDataContacts.getContactByEmail(messageData.message.fromAddress.email) || {};
                            messageData.message.fromAddress.displayAs = fromAddr.displayAs;
                        }

                        //data=default
                        //if (messageData.message.fromAddress.email) {
                        //	var domain = messageData.message.fromAddress.email.substring(messageData.message.fromAddress.email.lastIndexOf("@") + 1).toLowerCase();
                        //	if (userDataService.user.domain.toLowerCase() == domain) {
                        //		$http.get('~/api/v1/contacts/gal-image-link?email=' + messageData.message.fromAddress.email)
                        //			.then(function (success) {
                        //				$scope.avatarLoaded = true;
                        //				if (success.data && success.data.imageLink && success.data.imageLink.indexOf('data=default') === -1) {
                        //					messageData.message.avatarUrl = stSiteRoot + success.data.imageLink;
                        //				}
                        //				$timeout(function () { $rootScope.$broadcast('avatar.update-colors'); });
                        //			}, function (failure) {
                        //				$scope.avatarLoaded = true;
                        //				$timeout(function () { $rootScope.$broadcast('avatar.update-colors') });
                        //			});
                        //	} else {
                        //		var contact = coreDataContacts.getContactByEmail(messageData.message.fromAddress.email);
                        //		if (contact && contact.image && contact.image.indexOf("data=default") == -1) {
                        //			messageData.message.avatarUrl = (stSiteRoot !== '/' ? stSiteRoot : '') + contact.image;
                        //			$scope.avatarLoaded = true;
                        //			$timeout(function () { $rootScope.$broadcast('avatar.update-colors'); });
                        //		} else {
                        //			messageData.message.avatarUrl = '';
                        //			$timeout(function () { $rootScope.$broadcast('avatar.update-colors'); });
                        //			$scope.avatarLoaded = true;
                        //		}
                        //	}
                        //} else if (messageData.message.fromAddress.name) {
                        //	$scope.avatarLoaded = true;
                        //	$timeout(function () { $rootScope.$broadcast('avatar.update-colors'); });
                        //}




                        //TEST trusted stuff by hardcoding this
                        //messageData.message.trustInfo.trustedSenderLevel = 3;
                        //messageData.message.trustInfo.mismatchFromAddress = true;
                        //messageData.message.trustInfo.authenticated = true;
                        //messageData.message.trustInfo.dmarcStatus = 2;
                        //messageData.message.trustInfo.dkimStatus = 1;
                        //messageData.message.trustInfo.spfStatus = 2;
                        //messageData.message.trustInfo.totalLevel = 2;


                        messageData.senderIsBlocked = false;

                        if (!messageData.message.meetingInfo) {
                            loadIframe(messageData.message, messageData.mode); // load into view mode
                        } else {
                            // loadInviteIframe(messageData.message); // load into meeting invite mode

                            // adjust buttons for sent, junk
                            let userActionState = messageData.message.meetingInfo.recipientInfo.meetingInviteAction;
                            if (userActionState !== 1 && userActionState !== 3 && vm.isInJunk) {
                                messageData.message.meetingInfo.showAccept = false;
                                messageData.message.meetingInfo.showDecline = false;
                                messageData.message.meetingInfo.showPropose = false;
                                messageData.message.meetingInfo.showDetails = false;
                            }
                        }

                        if (vm.onRestrictedAddressesUpdated) {
                            vm.onRestrictedAddressesUpdated();
                        }
                        vm.onRestrictedAddressesUpdated = $scope.$on('restricted-addresses-updated', restrictionUpdate);

                        if (vm.onUserSettingsLoad) {
                            vm.onUserSettingsLoad();
                        }
                        vm.onUserSettingsLoad = $scope.$on('user-settings:loaded', restrictionUpdate);
                        restrictionUpdate();

                        if (messageData.message && messageData.message.attachments.length > 1) {
                            var totalSize = 0;
                            for (var i = 0; i < messageData.message.attachments.length; ++i) {
                                totalSize += messageData.message.attachments[i].size;
                            }
                            messageData.message.attachments.push({ allAttachments: true, filename: $translate.instant('FILENAME_ALL_ATTACHMENTS'), size: totalSize, link: messageData.message.allAttachmentsLink });
                            //messageData.message
                        }
                    }
                    if ($scope.isPopout && !messageData.message.isSeen) {
                        $scope.markMessage('read', messageData.message.uid, true);
                    }
                    vm.messageDoesNotExist = false;
                    messageData.messageLoaded = true;

                    $rootScope.$broadcast('mail.messageDoesNotExist', vm.messageDoesNotExist);

                } finally {
                    $scope.hideNoMessageText = false;
                    $rootScope.$applyAsync();
                }

                function restrictionUpdate() {
                    if (!messageData.message.fromAddress) return;

                    messageData.isTrustedSender = messageData.message.trustInfo.trustedSenderLevel > 0;
                    switch (messageData.message.trustInfo.trustedSenderLevel) {
                        case 0: messageData.trustedFromSource = "NONE"; break;
                        case 1: messageData.trustedFromSource = "USER"; break;
                        case 2: messageData.trustedFromSource = "CONTACTS"; break;
                        case 3: messageData.trustedFromSource = "DOMAIN"; break;
                        case 4: messageData.trustedFromSource = "GAL"; break;
                        case 5: messageData.trustedFromSource = "SYSTEM"; break;
                        default: messageData.trustedFromSource = "OTHER"; break;
                    }

                    messageData.senderIsBlocked = restrictedSenders.isBlockedSender(messageData.message.fromAddress.email);

                    //var spamHeader = "X-SmarterMail-TotalSpamWeight:";
                    //var index = messageData.message.header.indexOf(spamHeader);
                    //var endIndex = messageData.message.header.indexOf("\n", index);
                    //var spamWeight = 0;
                    //if (index > -1 && endIndex > -1) {
                    //    var line = messageData.message.header.substring(index + spamHeader.length, endIndex);
                    //    var reasonIndex = line.indexOf("(");
                    //    if (reasonIndex > -1)
                    //        line = line.substring(0, reasonIndex);
                    //    spamWeight = Number(line);
                    //}

                    messageData.isSenderInGAL = restrictedSenders.isSenderInGAL(messageData.message.fromAddress.email);
                    //messageData.isSenderTrusted = success.isTrusted;
                    //messageData.trustedSenderSource = success.trustSource;
                }
                // Else a previous call is returning late, after the user has already selected a different email
            }

            function processError(response) {


                if (params.softReload) {
                    $scope.mailPanelGrid.selectItemByIndex($scope.mailPanelGrid.getItemCount() - 2, event);
                    $scope.$applyAsync();
                    return;
                }

                messageData.messageLoaded = false;
                $scope.hideNoMessageText = false;
                vm.messageDoesNotExist = true;
                $rootScope.$broadcast('mail.messageDoesNotExist', vm.messageDoesNotExist);
            }
        }

        function categoryColorByName(catName) {
            const cat = getCategoryByName(catName);
            if (!cat || cat.colorIndex == -1)
                return null;
            const color = coreDataCategories.getCategoryColor(cat.colorIndex);
            if (!color || !color.rgb)
                return null;
            return color.rgb;

            function getCategoryByName(categoryName) {
                if (!categoryName) return null;
                var results = $.grep(messageData.messageCategories, (cat) => cat.name.toUpperCase() === categoryName.toUpperCase());
                return results.length > 0 ? results[0] : null;
            }
        }

        function getStatusMessage(length) {
            if (length && length > 0) {
                return $filter('translate')("YOU_HAVE_MESSAGES_SELECTED", { count: length });
            }
            return $filter("translate")("NO_MESSAGE_SELECTED");
        }

        function onMailListLoaded() {
            vm.isLoaded = true;
        }

        function onMailListUnloaded() {
            vm.isLoaded = false;
        }

        function onMailSelectionCountChanged(ev, data) {
            vm.selectStatusMessage = getStatusMessage(data);
        }

        function onStorageChanged() {
            if (window.location.href.indexOf('popout') !== -1) {
                var uids = preferencesStorage.getLocalParam("deletedUids");
                if (uids && uids.length > 0) {
                    checkIfMessageGone(uids);
                }
            }
        }

        function onCategoryChange(category) {
            category.selected = !category.selected;

            var categoryParams = { removeCategories: !category.selected, categories: [category.name] };
            $scope.markMessageCategories(categoryParams, messageData.message.uid);
        }

        function onClearAllCategories() {
            var categoryParams = { clearCategories: true };
            $scope.markMessageCategories(categoryParams, messageData.message.uid);
        }

        function proposalAccept() {
            inviteDetails(null, messageData.message.meetingInfo, true);
        }

        function setCategoryForm(form) {
            vm.categoryEditsForm = form;
        }

        function resetCategoryForm() {
            $scope.setupCategories();
            vm.categoryEditsForm.$setPristine();
            vm.categoryEditsForm.$setUntouched();
        }

        function saveCategoryChanges() {
            if (vm.isInSharedFolder) return;
            var categories = messageData.messageCategories.reduce(function (result, cat) {
                if (cat.selected)
                    result.push(cat.name);
                return result;
            }, []);
            var categoryParams = { clearCategories: true, categories: categories };
            $scope.markMessageCategories(categoryParams, messageData.message.uid);
            messageData.message.categories = categories;
            vm.resetCategoryForm();
        }

        function ListenPrintHotkey(event) {
            if (event.keyCode == 80 && (event.metaKey || event.ctrlKey)) { //ctrl + p
                event.preventDefault();
                if (!$scope.isPopout) {
                    var selectedItems = $scope.getSelectedUids();
                    if (selectedItems && selectedItems.length) {
                        emailFunctions.showPrintPopupAfterLoad(
                            $scope.tree.selectedBranchData.owner,
                            $scope.tree.selectedBranchData.path,
                            selectedItems[0],
                            (messageData && messageData.mode) || 'html',
                            false
                        );
                    }
                } else {
                    $scope.printMessage();
                }
            }
        }

        function loadIframe(contents, mode) {
            if (messageData.message && messageData.message.foundTrackers && messageData.message.foundTrackers.length > 0) {
                for (var i = 0; i < messageData.message.foundTrackers.length; i++) {
                    var ft = messageData.message.foundTrackers[i];
                    contents.messageHTML = emailTracker.replaceTracker(contents.messageHTML, ft.id);
                }
            }
            var iframe = document.getElementById('messageViewFrame');
            if (iframe && iframe.contentWindow && typeof iframe.contentWindow.setHTML === 'function') {
                contents.translations = contents.translations || {};
                contents.translations.MESSAGE_HAS_NO_TEXT_CONTENT = $translate.instant('MESSAGE_HAS_NO_TEXT_CONTENT');
                contents.translations.MESSAGE_HAS_NO_HTML_CONTENT = $translate.instant('MESSAGE_HAS_NO_HTML_CONTENT');
                contents.translations.MESSAGE_ENCRYPTED_CANNOT_VIEW = $translate.instant('MESSAGE_ENCRYPTED_CANNOT_VIEW');
                contents.translations.MESSAGE_SIGNED = $translate.instant('MESSAGE_SIGNED');
                iframe.contentWindow.scrollTo(0, 0);
                iframe.contentWindow.setHTML(contents, mode);
                iframe.style.display = 'block';
                $timeout(function () { vm.iFrameLoaded = true; });
            } else {
                $timeout(function () { loadIframe(contents, mode); }, 200);
            }
        }

        function onRefreshView() {
            if (!messageData.message.meetingInfo || messageData.mode === "header") {
                loadIframe(messageData.message, messageData.mode); // load into view mode
            } else {
                //loadInviteIframe(messageData.message); // load into meeting invite mode
            }
        }

        async function rejectReadReceipt() {
            try {
                await emailFunctions.rejectReadReceipt(messageData.message.ownerEmailAddress, messageData.message.folder, messageData.message.uid);
                messageData.message.requestingReadReceipt = false;
            } catch (err) {
                errorHandling.report(err);
            }
        }

        function unloadMessage() {
            $scope.hideNoMessageText = true;
            $rootScope.$broadcast('mail.hideMessagePaneMobile');
            messageData.unloadMessage();
            if (loadTextTimeout) $timeout.cancel(loadTextTimeout);
            loadTextTimeout = $timeout(function () { $scope.hideNoMessageText = false; }, 300);
        }

        async function allowRemoteForMessage(origin) {
            if (!origin) {
                await $scope.markMessage('bypassRemoteContent', messageData.message.uid, true);
                $timeout(() => reloadMessage(true));
            } else if (origin == "ALL_ORIGINS") {
                var originList = angular.copy(messageData.message.blockedContentOrigins);
                originList.length = originList.length - 1;
                await appendToExceptionList(originList);
            } else {
                await appendToExceptionList([origin]);
            }

            async function appendToExceptionList(origins) {
                try {
                    $rootScope.spinner.show();

                    let success = await $http.get('~/api/v1/settings/user-mail');
                    let newList = [].concat(success.data.userMailSettings.remoteContentExceptions).concat(origins);
                    let params = {
                        userMailSettings: {
                            remoteContentExceptions: newList
                        }
                    };

                    await $http.post('~/api/v1/settings/user-mail', JSON.stringify(params));
                    $timeout(() => reloadMessage(true));
                } catch (err) {
                    errorHandling.report(err);
                } finally {
                    $rootScope.spinner.hide();
                }
            }
        }

        function closeWindow() {
            window.close();
        }

        // Invites
        async function inviteAccept() {
            try {
                $rootScope.spinner.show();
                const invite = messageData.message.meetingInfo;
                await emailInviteFunctions.acceptInvite(invite.calendarOwner, invite.calendarId, invite.eventId, invite.raw, invite.replyingComment);
                $scope.markMessage('read', messageData.message.uid, true);
                if (messageData.navigationPacket.folder != "deleted items") {
                    $scope.$parent.deleteClicked(messageData.message.uid, undefined, undefined, undefined, true);
                } else {
                    toaster.pop("success", $translate.instant("RESPONSE_SENT"), $translate.instant("RESPONSE_SENT_SUCCESS"));
                }
            } catch (err) {
                errorMessageService.showErrorMessage(err);
            } finally {
                $rootScope.spinner.hide();
            }
        }

        async function inviteDecline() {
            try {
                $rootScope.spinner.show();
                const invite = messageData.message.meetingInfo;
                await emailInviteFunctions.declineInvite(invite.calendarOwner, invite.calendarId, invite.eventId, invite.raw, invite.replyingComment);
                $scope.markMessage('read', messageData.message.uid, true);
                if (messageData.navigationPacket.folder != "deleted items") {
                    $scope.$parent.deleteClicked(messageData.message.uid, undefined, undefined, undefined, true);
                } else {
                    toaster.pop("success", $translate.instant("RESPONSE_SENT"), $translate.instant("RESPONSE_SENT_SUCCESS"));
                }
            } catch (err) {
                errorMessageService.showErrorMessage(err);
            } finally {
                $rootScope.spinner.hide();
            }
        }

        async function inviteTentativeAccept() {
            try {
                $rootScope.spinner.show();
                const invite = messageData.message.meetingInfo;
                await emailInviteFunctions.tentativeAcceptInvite(invite.calendarOwner, invite.calendarId, invite.eventId, invite.raw, invite.replyingComment);
                $scope.markMessage('read', messageData.message.uid, true);
                if (messageData.navigationPacket.folder != "deleted items") {
                    $scope.$parent.deleteClicked(messageData.message.uid, undefined, undefined, undefined, true);
                } else {
                    toaster.pop("success", $translate.instant("RESPONSE_SENT"), $translate.instant("RESPONSE_SENT_SUCCESS"));
                }
            } catch (err) {
                errorMessageService.showErrorMessage(err);
            } finally {
                $rootScope.spinner.hide();
            }
        }

        async function inviteDelete() {
            try {
                $rootScope.spinner.show();
                const invite = messageData.message.meetingInfo;
                await emailInviteFunctions.deleteInvite(invite.calendarOwner, invite.calendarId, invite.eventId, invite.recurrenceID);
                if (messageData.navigationPacket.folder != "deleted items") {
                    $scope.$parent.deleteClicked(messageData.message.uid);
                } else {
                    toaster.pop("success", $translate.instant("RESPONSE_SENT"), $translate.instant("RESPONSE_SENT_SUCCESS"));
                }
            } catch (err) {
                errorMessageService.showErrorMessage(err);
            } finally {
                $rootScope.spinner.hide();
            }
        }

        async function openProposeChangesModal(ev) {
            try {
                var invite = {
                    info: messageData.message.meetingInfo,
                    owner: messageData.message.ownerEmailAddress,
                    calId: messageData.message.meetingInfo.calendarId
                };
                await emailInviteFunctions.openProposeChangesModal(invite, invite.info.start, invite.info.eventId, ev, invite.info.replyingComment);
                $scope.markMessage('read', messageData.message.uid, true);
                if (messageData.navigationPacket.folder != "deleted items") {
                    $scope.$parent.deleteClicked(messageData.message.uid, undefined, undefined, undefined, true);
                } else {
                    toaster.pop("success", $translate.instant("RESPONSE_SENT"), $translate.instant("RESPONSE_SENT_SUCCESS"));
                }
            } catch (err) {
                errorMessageService.showErrorMessage(err);
            }
        }

        function inviteCheck(ev) {
            const invite = messageData.message.meetingInfo;
            emailInviteFunctions.showAvailabilityModal(invite.start, invite.attendees, ev);
        }

        function inviteDetails(event, meetingInfo, acceptProposalFrom) {
            if (messageData.message.meetingInfo.isDeleted) {
                errorHandling.report($translate.instant("ERROR_MEETING_DOES_NOT_EXIST"));
                return;
            }
            var owner = messageData.message.meetingInfo.calendarOwner || "";
            var calId = messageData.message.meetingInfo.calendarId || "";
            var eventId = messageData.message.meetingInfo.eventId || "";
            if (!owner || !calId || !eventId) {
                var missingInfo = [];
                if (!owner) missingInfo.push("owner");
                if (!calId) missingInfo.push("calId");
                if (!eventId) missingInfo.push("eventId");
                return;
            }

            const popObj = {
                owner: owner,
                calId: calId,
                id: eventId,
                data: {
                    start: messageData.message.meetingInfo.start,
                    allDay: messageData.message.meetingInfo.isAllDay
                }
            };
            if (acceptProposalFrom) {
                popObj.data.proposal = {
                    start: messageData.message.meetingInfo.start,
                    end: messageData.message.meetingInfo.end
                };
            }

            localStorage.apptPopout = JSON.stringify(popObj);
            var redirect = `#/popout/appointment/${owner}/${calId}/${eventId}`;
            if (messageData.message.meetingInfo.recurrenceID) {
                redirect += `?instanceId=${moment(messageData.message.meetingInfo.recurrenceID).utc().format("YYYYMMDDHHmmss")}`;
            }
            window.open(redirect, "_blank", "resizable=1, " + popupService.dimensions.calendar);
        }

        function replyClicked(mode) {
            $scope.markMessage('read', messageData.message.uid, true);

            const packet = emailNavigation.makeComposePacket({
                owner: messageData.navigationPacket.owner,
                folder: messageData.navigationPacket.folder,
                uid: messageData.navigationPacket.uid,
                reply: mode
            });
            const url = emailNavigation.getPopoutComposeUrl(packet);

            if ($scope.isPopout) {
                $window.location.href = url;
            } else {
                window.open(url, '', 'resizable=1, ' + popupService.dimensions.email);
            }
        }

        function forwardClicked(mode) {
            const packet = emailNavigation.makeComposePacket({
                owner: messageData.navigationPacket.owner,
                folder: messageData.navigationPacket.folder,
                uid: messageData.navigationPacket.uid,
                reply: mode
            });
            const url = emailNavigation.getPopoutComposeUrl(packet);

            if ($scope.isPopout) {
                $window.location.href = url;
            } else {
                window.open(url, '', 'resizable=1, ' + popupService.dimensions.email);
            }
        }

        function doResize() {
            setTimeout(function () { $('.emailPopout').scrollTop(0); }, 100);
        }

        function onDestroy() {
            $(window).off("resize.doResize"); //remove the handler added earlier
            $(document).off("keydown", ListenPrintHotkey);
        }

        function isEmailUsers(email) {
            return email === userDataService.user.emailAddress;
        }

        function checkIfMessageGone(uids) {
            for (var i = 0; i < uids.length; ++i) {
                if (messageData.message.uid === uids[i]) {
                    vm.messageDoesNotExist = true;
                    unloadMessage();
                    preferencesStorage.setLocalParam("deletedUids", []);
                    break;
                }
            }
        }

        async function onDownloadRaw(uid) {
            try {
                await emailFunctions.downloadEmlFile($scope.getActiveFolderOwner(), $scope.getActiveFolder(), uid || $scope.getSelectedUids());
            } catch (err) {
                errorHandling.report(err);
            }
        }
    }
})();